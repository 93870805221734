import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, KfAuthService, KfSharedConstantsService, KfSpinnerService } from '@kf-products-core/kfhub_lib';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Observable, Subject, of as observableOf } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface AnalysisList {
    analysisName?: string;
    dataSource?: string;
    countryCode?: string;
    createdDate?: number;
}

@Injectable()
export class PayReportingService {
    public config = environment().validationApp;
    private baseUrl = environment().baseApiUrl;
    private tempUrl = 'https://testproductshub.kornferry.com/paydatacollection/backup/postprocessing/';
    private relUrl = '/v1/hrms/paydatacollection/postprocessing';
    private dctErrorsUrl = '/v1/hrms/paydatacollection';
    private reportUrl = '/v1/hrms/reports/';
    private ceReportUrl = '/v1/hrms/cereporting/';
    private cptReference = '/v1/hrms/usermanagement/cpt';
    private _eventBus: Subject<any>;
    constructor(
        private authService: KfAuthService,
        private http: HttpClient,
        private constService: KfSharedConstantsService,
        public spinner: SpinnerVisibilityService,
        private spinnerService: KfSpinnerService) {
        this._eventBus = new Subject();

    }

    broadcast(key: String, data?: any) {
        this._eventBus.next({
            key, data,
        });
    }
    // the listener method
    on(key: String): Observable<any> {
        return this._eventBus.asObservable().pipe(
            filter(event => event.key === key),
            map(event => event.data),);
    }

    public getTablePaginationLimitValues(paginationData): Observable<Array<Number>> {
        const paginationDropDownValues: Array<Number> = [];
        paginationDropDownValues.push(paginationData.rowsPerPage);
        let i = 50;
        i = i * 2;
        if (i === 200) {
            i += 50;
        }
        if (i < 500) {
            paginationDropDownValues.push(i);
        } else {
            paginationDropDownValues.push(500);
        }
        return observableOf(paginationDropDownValues);
    }
    public getCountrieBasedOnModule(moduleName) {
        this.spinner.hide();
        this.spinnerService.enable();
        // this.spinnerService.disable();
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/actions/countries?moduleType=' + moduleName;
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public deployFactToProdService(data, type) {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const postData = {
            countryCode: [data],
        };
        let url;
        if (type === 'fact') {
            url = this.baseUrl + this.relUrl + '/sync/functions';
        } else {
            url = this.baseUrl + this.relUrl + '/functions';
        }
        const req = new HttpRequest('POST', url, postData, options);
        return this.http.request(req);
    }
    public getCompanyCount(id, countryCode) {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/selectioncriterias/counts/' + id + '?countryCode=' + countryCode + '&_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public getFactEligibilityNamesService() {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/actions/analysisattributes?countryCode=ALL&attributeType=ALL';
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public getAnalysisMetadata(countryCode): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/analysis/metadata?countryCode=' + countryCode + '&_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public getQueueList(type: string): Observable<any> {
       const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/actions/queue?queueType=' + type+ '&_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public getQueueListNew(apiPath: string): Observable<any> {
        
         const url = this.baseUrl + this.relUrl + apiPath;
         return this.authService
            .authHttpCall('GET', url, null, null, true);
    }

    public getCeReferenceList(apiPath: string): Observable<any> {
        
        const url = this.baseUrl + this.ceReportUrl + apiPath;
        return this.authService
           .authHttpCall('GET', url, null, null, true);
   }

    public getCeQueueList(apiPath: string): Observable<any> {
        
        const url = this.baseUrl + this.ceReportUrl + apiPath;
        return this.authService
           .authHttpCall('GET', url, null, null, true);
   }
    public userList(apiPath: string): Observable<any> {
        
        const url = this.baseUrl + apiPath;
        return this.authService
           .authHttpCall('GET', url, null, null, true);
   }
    public getQueueListNew1(apiPath: string): Observable<any> {
        
        const url = apiPath;
        return this.authService
           .authHttpCall('GET', url, null, null, true);
   }
    public getQueueConfig(apiPath: string): Observable<any> {
        
        const url = this.baseUrl + this.relUrl + apiPath;
        return this.authService
           .authHttpCall('GET', url, null, null);
   }
   public getCEQueueConfig(apiPath: string): Observable<any> {
        
        const url = this.baseUrl + this.ceReportUrl + apiPath;
        return this.authService
        .authHttpCall('GET', url, null, null);
    }
    public getDctErrorsConfig(apiPath: string): Observable<any> {
        const url = this.baseUrl + this.dctErrorsUrl + apiPath;
        return this.authService
            .authHttpCall('GET', url, null, null);
    }
    public getDctErrorsList(apiPath: string): Observable<any> {
        const url = this.baseUrl + this.dctErrorsUrl + apiPath;
        return this.authService
            .authHttpCall('GET', url, null, null, true);
    }
    public getSavedAnalysisByCountry(countryCode): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/analysis?countryCode=' + countryCode + '&_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public getAnalysisById(id): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/analysis/' + id + '?_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public getHTTPData(path: string, mock: boolean) {
        // 'assets/data/mock-countries.json'
        let url;
        if (mock) {
            url = path;
        } else {
            url = this.baseUrl + this.relUrl + path;
        }
        return this.authService
            .authHttpCall('GET', url, null, null, true);
    }
    public getColumnListByTable(table): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        // tslint:disable-next-line: max-line-length
        const url = this.tempUrl + 'analysis/marketdefinition/attributes.json?sourceEntity=' + table + '&_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public getReferenceTableData(data): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        let url = '';
        if (data === 'ALL') {
            url = this.baseUrl + this.relUrl + '/maintenance/referencetables?_=' + +new Date();
        } else {
            url = this.baseUrl + this.relUrl + '/maintenance/referencetables?tableName=' + data + '&_=' + +new Date();
        }
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public postReferenceTabledata(data): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/maintenance/referencetables';
        const req = new HttpRequest('POST', url, data, options);
        return this.http.request(req);
    }
    public getEntitydata(countryCode, attributeType = 'ALL', moduleType?: string): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        let apiUrl = '/actions/analysisattributes?countryCode=' + countryCode + '&attributeType=' + attributeType;
        if (moduleType) {
            apiUrl = apiUrl + '&moduleType=' + moduleType;
        }
        const url = this.baseUrl + this.relUrl + apiUrl
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public getUsageTypeMetadata(): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        // tslint:disable-next-line: max-line-length
        const url = this.baseUrl + this.relUrl + '/actions/metadata';
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public postAnalysysData(postData: any): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.tempUrl + 'analysis/createanalysis.json';
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public createAnalysysData(postData: any): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/analysis';
        const req = new HttpRequest('POST', url, postData, options);
        return this.http.request(req);
    }
    public updateAnalysysData(postData: any, id): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/analysis/' + id;
        const req = new HttpRequest('PUT', url, postData, options);
        return this.http.request(req);
    }
    public createMarketDefinitionData(postData: any): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/selectioncriterias';
        const req = new HttpRequest('POST', url, postData, options);
        return this.http.request(req);
    }
    public updateMarketDefinitionData(id: number, postData: any): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/selectioncriterias/' + id;
        const req = new HttpRequest('PUT', url, postData, options);
        return this.http.request(req);
    }
    public deleteMarketDefinitionData(id: number): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/selectioncriterias/' + id;
        const req = new HttpRequest('DELETE', url, '', options);
        return this.http.request(req);
    }
    public deleteDT(countryCode: string, columnName:string ): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/datatransformations?countryCode='+ countryCode + '&lookupName='+ columnName + '&_=' + +new Date();
        const req = new HttpRequest('DELETE', url, '', options);
        return this.http.request(req);
    }
    public getMarketDefinitionList(countryCode: string): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/selectioncriterias?countryCode=' + countryCode + '&_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        // let dats =_.find(data, { 'id': id});
        return this.http.request(req);
    }
    public getMarketDefinitionListById(id): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/selectioncriterias/' + id;
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public getPercentileTableList(id): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/percentilelists/' + id;
        const req = new HttpRequest('GET', url, '', options);
        // let dats =_.find(data, { 'id': id});
        return this.http.request(req);
    }
    public getFactTableList(id): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/factlists/' + id + '?_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        // let dats =_.find(data, { 'id': id});
        return this.http.request(req);
    }


    public createFactListData(postData: any): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/factlists';
        const req = new HttpRequest('POST', url, postData, options);
        return this.http.request(req);
    }
    public updateFactListData(id: number, postData: any): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/factlists/' + id;
        const req = new HttpRequest('PUT', url, postData, options);
        return this.http.request(req);
    }
    public getBatchData(id1: string, id2: string, mdId): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
            responseType: 'text',
        };
        let url = this.baseUrl + this.relUrl + '/analysis/batches/items?attributeId=' + id1 + '&denormId=' + id2;
        if (mdId && mdId != '-1') {
            url = url + '&marketDefinitionId=' + mdId;
        }
        const req = new HttpRequest('GET', url, options);
        return this.http.request(req);
    }

    public getAnalysisReportDownload(type, postData: any): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
            responseType: 'text',
        };
        const url = this.baseUrl + this.relUrl + '/analysis/downloadreports?' + 'queueId='
            + postData.typeId + '&reportType=' + type;

        const req = new HttpRequest('GET', url, options);
        return this.http.request(req);
    }

    public downloadQueueData(id, postData: any, queryParam: string = null): Observable<any> {

        // const options = {
        //     headers,
        //    // responseType: 'text',
        // };
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );



        let url = this.baseUrl + this.reportUrl + 'downloadReports?reportId=' + id;
        if (queryParam) {
            url = url + queryParam;
        }

        const req = new HttpRequest('POST', url, postData, { headers, responseType: 'blob' });

        // const req = new HttpRequest('GET', url, options);
        return this.http.request(req);
    }

    public getDeNormDatabaseListwithCountryCode(countryCode): Observable<any> {
        const headers = new HttpHeaders(
            {
                authtoken: this.authService.AuthToken,
                authToken: this.authService.AuthToken,
            },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/denormdatabases?countryCode=' + countryCode + '&_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }

    public getDBNormMetadataByCountry(countryCode): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/denormdatabases/metadata?countryCode=' + countryCode;
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }

    public getDeNormDatabaseListwithId(id): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/denormdatabases/' + id + '?_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }

    public getAnalysisListDeNormDBqueue(type): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/actions/queue?queueType=' + type + '&_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
        // return Observable.of(listData)
    }

    public updateQueryData(data, queryType): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };

        let url = this.baseUrl + this.relUrl + '/denormdatabases/updatedata?_=' + +new Date();
        if (queryType === 'UPM') {
            url = this.baseUrl + this.relUrl + '/upm/updatedata?_=' + +new Date();
        }
        const req = new HttpRequest('POST', url, data, options);
        return this.http.request(req);
    }


    public listofDeNormDatabases(): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/denormdatabases?_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
        // return Observable.of(listData)
    }

    public addDeNormDatabases(analysisDBModel, submitMode, id): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        let req;
        let url = '';
        if (submitMode && submitMode === 'CREATE') {
            url = this.baseUrl + this.relUrl + '/denormdatabases?_=' + +new Date();
            req = new HttpRequest('POST', url, analysisDBModel, options);
        } else if (submitMode === 'UPDATE') {
            url = this.baseUrl + this.relUrl + '/denormdatabases/' + id + '?_=' + +new Date();
            req = new HttpRequest('PUT', url, analysisDBModel, options);
        }
        return this.http.request(req);
    }

    public submitDeNormDatabase(data): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };

        const url = this.baseUrl + this.relUrl + '/actions/queue?_=' + +new Date();
        const req = new HttpRequest('POST', url, data, options);
        return this.http.request(req);
    }

    public listpercentileData(countryCode): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/percentilelists?countryCode=' + countryCode;
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
        // return Observable.of(listData)
    }
    public getFactlist(countryCode): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/factlists?countryCode=' + countryCode + '&_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
        // return Observable.of(listData)
    }
    public getCEFactlists(countryCode, countryId): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        // tslint:disable-next-line: max-line-length
        const url = this.baseUrl + this.relUrl + '/calcengine/cecountries/dependents?countryCode=' + countryCode + '&countryId=' + countryId + '&_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
        // const res = {
        //     body: {
        //         data: factMock,
        //     },
        // };
        // return Observable.of(res);
    }
    public submitTransferToUPM(data): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };

        const url = this.baseUrl + this.relUrl + '/calcengine/transfertoupm?_=' + +new Date();
        const req = new HttpRequest('POST', url, data, options);
        return this.http.request(req);
    }
    public groupingcriteriasByName(name: string): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/groupingcriterias?attributeName=' + name;
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public deleteAnalysisQueue(id) {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/analysis/' + id;
        const req = new HttpRequest('DELETE', url, '', options);
        return this.http.request(req);
    }
    public deleteFact(id) {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/factlists/' + id;
        const req = new HttpRequest('DELETE', url, '', options);
        return this.http.request(req);
    }

    public saveQuery(postData: any): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },

        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/runquery' + '?version=2.0&type=SAVE';
        const req = new HttpRequest('POST', url, postData, options);
        return this.http.request(req).pipe();



        // let data = [];
        // return Observable.of(data);
    }

    public getCEDataList(data): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        // const url = this.baseUrl + this.relUrl + '/actions/queue?queueType=' + type;
        // const req = new HttpRequest('GET', url, '', options);
        //  return this.http.request(req);
        const res = {
            body: {
                // tslint:disable-next-line: object-literal-shorthand
                data: data,
            },
        };
        return observableOf(res);
    }
    public getCECountrylists(countryCode): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/calcengine/cecountries?countryCode=' + countryCode;
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);

    }
    public deferredQueueList(): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };

        const url = this.baseUrl + this.relUrl + '/actions/queue?queueType=DEFERRED_REPORT&_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
        // return Observable.of(mockData)
    }

    public generateDeferedReport(reportId, bodyobj) {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        // tslint:disable-next-line: max-line-length
        const url = this.baseUrl + this.relUrl + '/deferredreports?reportId=' + reportId + '&reportType=DEFERRED_REPORT';
        const req = new HttpRequest('POST', url, bodyobj, options);
        return this.http.request(req);
    }
    public submitCEDeferred(bodyobj) {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        // tslint:disable-next-line: max-line-length
        const url = this.baseUrl + this.ceReportUrl + 'reports/deferredreport';
        const req = new HttpRequest('POST', url, bodyobj, options);
        return this.http.request(req);
    }

    public downloadDeferredReport(rowData, postData: any): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        // tslint:disable-next-line: max-line-length
        const url = this.baseUrl + this.reportUrl + 'downloadReports?reportId=' + rowData.typeId + '&deferredReport=TRUE&queueId=' + rowData.queueId;
        const req = new HttpRequest('POST', url, postData, { headers, responseType: 'blob' });
        return this.http.request(req);
    }
    public downloadCEDeferredReport(reportId, postData: any): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        // tslint:disable-next-line: max-line-length
        const url = this.baseUrl + this.ceReportUrl + 'reports/downloadReports?reportId=' + reportId;
        const req = new HttpRequest('POST', url, postData, { headers, responseType: 'blob' });
        return this.http.request(req);
    }

    public executeSQLQuery(data, factId): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        let url = this.baseUrl + this.relUrl + '/functions';
        let requestType = 'POST';

        if (data && data.functionType === 'MODIFY_FUNCTION') {
            requestType = 'PUT';
            if (factId) {
                url = `${url}/${factId}`;
            }
        }

        const req = new HttpRequest(requestType, url, data, options);
        return this.http.request(req);
    }
    public getFactsData(data): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };

        const url = this.baseUrl + this.relUrl + '/functions?countryCode=' + data + '&_=' + +new Date();
        const req = new HttpRequest('GET', url, options);
        return this.http.request(req);
    }
    public deleteFactFunction(id: number): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/functions/' + id;
        const req = new HttpRequest('DELETE', url, '', options);
        return this.http.request(req);
    }
    public getEntityById(functionId): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        // tslint:disable-next-line: max-line-length
        const url = this.baseUrl + this.relUrl + '/functions/' + functionId;
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public getdatatransformationsData(countryCode, pIndex, pageSize, sortColumn, sortBy): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/datatransformations?countryCode='
            + countryCode + '&pageIndex=' + pIndex + '&pageSize=' + pageSize +
            '&sortColumn=' + sortColumn + '&sortBy=' + sortBy;
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    // tslint:disable-next-line: no-shadowed-variable
    public getMaintenanceCountryCode(code, pIndex, pageSize, filter, sortBy): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/datatransformations?countryCode='
            + code + '&pageIndex=' + pIndex + '&pageSize=' + pageSize + '&sortColumn=' + filter + '&sortBy=' + sortBy + '&_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    // tslint:disable-next-line: no-shadowed-variable
    public getMaintenanceColumnData(code, columnName, pIndex, pageSize, filter, sortBy): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/datatransformations?countryCode='
            + code + '&returnColumnName=' + columnName + '&pageIndex=' + pIndex
            + '&pageSize=' + pageSize + '&sortColumn=' + filter + '&sortBy=' + sortBy;
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public postMaintenanceColumnData(data): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/datatransformations';
        const req = new HttpRequest('POST', url, data, options);
        return this.http.request(req);
    }
    public getMaintenanceDataSubmit(data): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/maintenance/upm';
        const req = new HttpRequest('POST', url, data, options);
        return this.http.request(req);
    }
    public getMaintenanceMetadata(): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/maintenance/metadata?_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public getSPCategoryList(): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/maintenance/adminprocedurecategories';
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public getSPListByCategoryId(id: number): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/maintenance/adminprocedures?categoryId=' + id;
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public getParamListBySPId(id: number): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/maintenance/adminprocedures/parameters?adminProcedureId=' + id;
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public getParamValuesByParamName(id: number, pName: string, iValue: string): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/maintenance/adminprocedures/parametervalues?adminProcedureId=' + id + '&parameterName=' + pName + '&inputValues=' + iValue;
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public isUserHasAccess(userListArr): Observable<any> {
        userListArr.map(v => v.toLowerCase());
        let hasAccess = true;
        if (!userListArr.includes(this.authService.getSessionInfo().User.Username.toLowerCase())) {
            hasAccess = false;
        }
        return observableOf(hasAccess);
    }
    /// --------DISPLAY TEMPLATES-----
    public getTemplatesListByType(templateType, countryCode): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl +
            '/maintenance/reporttemplates?countryCode=' + countryCode + '&reportType=' + templateType+ '&_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public getTemplateDataByID(reportTemplateId: number): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/maintenance/reporttemplates/' + reportTemplateId;
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public createUpdateTemplate(analysisDBModel, submitMode): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        let req;
        let url = '';
        if (submitMode && (submitMode === 'CREATE' || submitMode === 'CLONE')) {
            url = this.baseUrl + this.relUrl + '/maintenance/reporttemplates?_=' + +new Date();
            req = new HttpRequest('POST', url, analysisDBModel, options);
        } else if (submitMode === 'UPDATE') {
            url = this.baseUrl + this.relUrl + '/maintenance/reporttemplates/' +
                analysisDBModel['reportTemplateId'] + '?_=' + +new Date();
            req = new HttpRequest('PUT', url, analysisDBModel, options);
        }
        return this.http.request(req);
    }

    public getCPTReferenceTable() {
        const url = this.baseUrl+this.cptReference;
        return this.authService.authHttpCall('GET', url);
    }

    public getCPTUserDropDown() {
        const url = this.baseUrl+this.cptReference+'/users';
        return this.authService.authHttpCall('GET', url);
    }
    
    public postCPTReferenceData(data): Observable<any> {
        const url = this.baseUrl + this.cptReference;
        return this.authService.authHttpCall('POST', url,data,null, true);
    }
    
    public deleteCPTUser(id): Observable<any> {
        const url = this.baseUrl + this.cptReference+'/'+id;
        return this.authService.authHttpCall('DELETE', url,null,null, true);
    }
}