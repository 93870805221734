
import {of as observableOf,  Observable, forkJoin, of } from 'rxjs';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, KfAuthService } from '@kf-products-core/kfhub_lib';
import { defaultColumns } from './data/default-template-columns';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
export interface ITemplateDataColumnValidationError {
    errorLevel: 'WARNING' | 'ERROR';
}

export interface TemplateDataColumnValidationDependencies extends ITemplateDataColumnValidationError {
    validation: string[];
    condition: string[];
}

export interface TemplateDataColumnValidationString extends ITemplateDataColumnValidationError {
    validation: string;
}

export interface TemplateDataColumnValidationStringArray extends ITemplateDataColumnValidationError {
    validation: string[];
}

export interface TemplateDataColumnValidationBoolean extends ITemplateDataColumnValidationError {
    validation: boolean;
}

export interface TemplateDataColumnValidationNumber extends ITemplateDataColumnValidationError {
    validation: number;
}

export interface TemplateDataColumnOperation {
    id: number;
    columnName: string;
    columns: string[];
    operation: 'ADDITION' | 'SUBSTRACTION' | 'MULTIPLICATION';
    action: string;
    tier2Transformation: boolean;
    columnComplexCalc: string;
}
export interface TemplateDataTransformation {
    id: number;
    columnName: string;
    operation: 'COMPLEX';
    transformationName: string;
    action: string;
    tier2Transformation: boolean;
    columns?: string[];
    columnComplexCalc?: string;
}
export interface TemplateSciptMapping {
    id: number;
    type: string;
    inputColumns: any[];
    outputColumns: any[];
    constants: string[];
    name:string;
    script:string;
    scriptType:string;
}

export interface TemplateDataColumnEntry {
    id?: string | number;
    xlsxDisplayColumnName: string;
    normalizedColumnName: string;
    displayColumnName: string;
    calcEngineMapping: string;
    dataAttributeName: string;
    type: TemplateDataColumnValidationString;
    required: TemplateDataColumnValidationBoolean;
    minValue: TemplateDataColumnValidationNumber;
    maxValue: TemplateDataColumnValidationNumber;
    allowedValues: TemplateDataColumnValidationStringArray;
    unique: TemplateDataColumnValidationBoolean;
    groupPresence: TemplateDataColumnValidationStringArray;
    dependentColumns: TemplateDataColumnValidationDependencies;
}
export interface TemplateDataSourcesEntry {
    created?: TemplateDataSourceEntry[];
    modified: TemplateDataSourceEntry[];
    // published: TemplateDataSourceEntry[];
}
export interface TemplateDataSourceEntry {
    id?: string | number;
    email: string;
    dateTimestamp: number;
}
export interface CountryDataEntry {
    id: string;
    name: string;
    code: string;
    country: string;
    sources: Updated[];
    updatedEmail?: any;
    updatedCountryDueDate: any;
}
export interface Updated {
    id: string;
    updatedTimeStamp: number;
    updatedName: string;
    updatedType: string;
    countryDueDate?: Date;
    emailAddresses?: any;
}
export interface Email {
    emailAddress: string[];
    updatedTimeStamp: number | Date;
    updatedUser: string;
}
export interface EditType {
    id: number;
    updatedUser: string;
    updatedTimeStamp: number | Date;
}
export interface TemplateDataEntry {
    id: string;
    languageName: string;
    display: boolean;
    country: string;
    subCountry: string;
    language: string;
    locale: string;
    currency: string;
    effectiveDate: string | Date;
    dateFormat: string;
    sector: string;
    isDeleted: boolean;
    createDate: number;
    modifiedDate: number;
    filename: string;
    version: string;
    dataWorksheetName: string;
    primaryDataSource: string;
    live: boolean;
    columns: TemplateDataColumnEntry[];
    excludedColumns: string[];
    calculatedColumns: Array<TemplateDataColumnOperation | TemplateDataTransformation>;
    transformationScripts:Array<TemplateSciptMapping> ;
    sources:TemplateDataSourcesEntry;
    compRatioMin: number;
    compRatioMax: number;
    attributeType: string;
    attributeValue: any;
    defaultTemplate: boolean;

}

@Injectable()
export class ValidationTemplateService {
    public countries = [];
    public baseApiUrl = environment().baseApiUrl;
    private config = environment().validationApp;
    private baseUrl = this.config.templateBaseApiUrl + 'templates';

    private getAllCountryDueDate = this.baseApiUrl +
    this.config.countryDueDateUrl + '?countryCode=';

    private updateAllCountryDueDate = this.baseApiUrl +
    this.config.updateCountryDueDateUrl;

    private updateEmailAddressApiUrl = this.baseApiUrl +
    this.config.updateEmailAddressUrl;

    private updateLeaderboardCountryDueDate = this.baseApiUrl +
    this.config.updateLeaderboardCountryDueDate;

    private actionUrl = this.baseUrl + '/actions';
    private xlsxGenerateApiUrl = this.config.xlsxGenerateApiUrl;
    private xlsxPublishApiUrl = this.config.xlsxPublishApiUrl;
    private uploadT1TemplateUrl = this.config.s3T2FileServiceURL;
    private getT1TemplateWarningsUrl = this.config.s3T2FileServiceURL;
    private downloadT1TemplateUrl = this.config.s3T2FileServiceURL;
    private versionDctUrl = 'v1/hrms/dct/';
    private DCTType = 'type=t1RulesTemplate';
    private DCTRules = 'rules';
    private DCTStatus = 't1Status';
    private version:string = 'v1';
    private hrms:string = '/hrms';
    private payDataCollection:string = '/paydatacollection';
    private postprocessing:string = '/postprocessing';
    private template:string = '/template';

    private options: { headers?: HttpHeaders } = {};

    constructor(
        private httpClient: HttpClient,
        private authService: KfAuthService,
    ) {
        const authToken = this.authService.AuthToken;
        if (this.config.useAuthHeader && authToken) {
            const headers = new HttpHeaders({ authToken });
            this.options = { headers };
        }
    }

    updateAuthHeader() {
        const authToken = this.authService.AuthToken;
        if (this.config.useAuthHeader && authToken) {
            const headers = new HttpHeaders({ authToken });
            this.options = { headers };
        }
    }

    public getHTTPData(url: string, mock: boolean) {
       
        return this.authService.authHttpCall('GET', url, null, null, true);
    }
    public getTemplatesData(countryCode:string): Observable<TemplateDataEntry[]> {
        return this.authService.authHttpCall('GET', this.baseUrl+'?countryCode='+countryCode, null, null, true);
    }

    public getTemplateById(id: string): Observable<TemplateDataEntry> {
        this.updateAuthHeader();
        return this.httpClient.get<TemplateDataEntry>(this.baseUrl + '/' + id, this.options);
    }

    public publishTemplate(template: TemplateDataEntry): Observable<Object> {
        this.updateAuthHeader();
        return this.httpClient.post(this.xlsxPublishApiUrl, template, this.options);
    }

    public addTemplate(template: TemplateDataEntry): Observable<Object> {
        this.updateAuthHeader();
        return this.httpClient.post(this.baseUrl, template, this.options);
    }

    public deleteTemplate(id: string): Observable<Object> {
        this.updateAuthHeader();
        return this.httpClient.post(
            this.actionUrl,
            {
                action: 'delete',
                id,
            },
            this.options,
        );
    }
    public validateT1Template(template:any):Observable<any> {
        this.updateAuthHeader();
        return this.httpClient.post(this.getT1TemplateWarningsUrl+this.versionDctUrl+this.DCTStatus, template, this.options);

    }

    public uploadT1Template(template:any):Observable<any> {
        this.updateAuthHeader();
        return this.httpClient.post(this.getT1TemplateWarningsUrl+this.versionDctUrl+this.DCTRules+'?'+this.DCTType, template, this.options);

    }

    public setAsDefaultTemplate(template:any):Observable<any> {
        this.updateAuthHeader();
        return this.httpClient.post(this.actionUrl, template, this.options);
    }

    public setAsDefaultTemplateParallel(template:any):Observable<any> {
        this.updateAuthHeader();
        return this.httpClient.post(this.baseApiUrl + '/' +this.version + this.hrms + this.payDataCollection + this.postprocessing + this.template , template, this.options);
    }
    

    fileUpload(url: string, file: File): Observable<any> {
        this.updateAuthHeader();
        return this.httpClient.put(url,file, this.options);
    }

    public getT1TemplateWarnings(template:any):Observable<any> {
        this.updateAuthHeader();
        // return Observable.of({});
        return this.httpClient.get(this.getT1TemplateWarningsUrl+this.versionDctUrl+this.DCTRules+'?'+this.DCTType + '&' + new URLSearchParams(template).toString(), this.options);

    }

    public uploadT1TemplateFile(presignedUrl:string,file:any):Observable<any> {
        this.updateAuthHeader();
        return this.httpClient.post(presignedUrl, file, this.options);

    }

    public publishLive(id: string, live: boolean): Observable<Object> {
        this.updateAuthHeader();
        let env = this.config.environment
        return this.httpClient.post(
            this.actionUrl,
            {
                action: 'publish',
                live,
                id,
                environment:env
            },
            this.options,
        );
    }

    public publishEnv(id: string, env: string): Observable<Object> {
        this.updateAuthHeader();
        return this.httpClient.post(
            this.actionUrl,
            {
                action: 'publish',
                id,
                environment: env,
            },
            this.options,
        );
    }

    public publishAll(id: string, type: string): Observable<Object> {
        this.updateAuthHeader();
        const markers = [];
        if (type == 'all') {
            const res1 =  this.httpClient.post(
                this.actionUrl,
                {
                    action: 'publish',
                    environment:'ALL',
                    id,
                },
                this.options,
            );
            // const res2 =  this.httpClient.post(
            //     this.actionUrl,
            //     {
            //         action: 'publish',
            //         live:false,
            //         id,
            //     },
            //     this.options,
            // );

            markers.push(res1);
            return forkJoin(markers);
        }
        return observableOf({});
    }

    public saveTemplate(template: TemplateDataEntry): Observable<Object> {
        template.modifiedDate = Date.now();
        this.updateAuthHeader();
        if (template.id) {
            return this.httpClient.put(this.baseUrl, template, this.options);
        } else {
            return this.httpClient.post(this.baseUrl, template, this.options);
        }
    }

    public generateExcel(template: TemplateDataEntry): Observable<any> {
        this.updateAuthHeader();
        return this.httpClient.post(
            this.xlsxGenerateApiUrl,
            template,
            _.defaults({ responseType: 'text' }, this.options),
        );
    }

    public generateNewTemplate(): TemplateDataEntry {
        return {
            createDate: Date.now(),
            modifiedDate: Date.now(),
            columns: defaultColumns,
            excludedColumns: ['CompanyOrgcode'],
            calculatedColumns: [],
        } as any;
    }

    public generateNewColumn(): TemplateDataColumnEntry {
        return {
            type: { validation: 'STRING' },
            required: { validation: false },
            minValue: {},
            maxValue: {},
            allowedValues: {},
            unique: { validation: false },
            groupPresence: {},
            dependentColumns: {},
        } as any;
    }
    public exportAsExcelFile(json: any[], excelFileName: string): void {

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        // console.log('worksheet',worksheet);
        const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    }

    public updateEmailAddress(email: any) {
        // console.log('updateEmailAddress: ', email);
        // const updateEmail = 'http://localhost:3030/paydatacollection/actions' +
        // '/leaderboard/updateemailaddress';
        const updateEmailUrl = this.updateEmailAddressApiUrl;
        console.log(' updateEmailUrl!!!', updateEmailUrl);
        return this.authService
        .authHttpCall('PUT', updateEmailUrl, email,{ 'Content-Type': 'application/json' },true);
    }

    public getCountryDueDate(code: string): Observable<CountryDataEntry[]> {
        const getCountryUrl = this.getAllCountryDueDate + code;
        // const getCountryUrl = 'http://localhost:3030/paydatacollection/actions' +
        // '/leaderboard/countryduedates?countryCode=' + code;

        return this.authService
        .authHttpCall('GET', getCountryUrl);
    }

    public updateCountryDueDate(countryInfo) {
        // this.updateAllCountryDueDate = 'http://localhost:3030/paydatacollection/actions/leaderboard/countryduedates';
        return this.authService
        .authHttpCall('PUT', this.updateAllCountryDueDate, countryInfo,{ 'Content-Type': 'application/json' },true);
    }

    public updateCountryLeaderboardDueDate(countryInfo) {
        // this.updateLeaderboardCountryDueDate =
        // 'http://localhost:3030/paydatacollection/actions/leaderboard/updateleaderboardcountrydate';
        return this.authService.authHttpCall(
            'PUT', this.updateLeaderboardCountryDueDate, countryInfo,{ 'Content-Type': 'application/json' },true);
    }
}
