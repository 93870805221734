import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import {
    KfAuthService,
    KfSharedConstantsService,
    KfIsearchResultsDataService,
    KfFilterMetadata,
    KfIpagedSearchResults,
} from '@kf-products-core/kfhub_lib';

export interface EmailDetails {
    emailName : string,
    createdBy:string,
    createdDate :string,
    modifiedBy : string,
    modifiedDate :string,
}
import axios from 'axios';

@Injectable()
export class EmailService implements KfIsearchResultsDataService{
    public filter = '';
    loggedInClientId: any;
    userId: any;
    private readonly emailPrefix = '/v1/hrms/paydatacollection/email/searchemailtemplates';
    private readonly countryPrefix = '/v1/hrms/payhub/references/payreferencedata';
    private readonly industryPrefix = '/v1/hrms/paydatacollection/references/industries';
    private readonly defaultTemplate = '/v1/hrms/paydatacollection/email/defaulttemplate/-1'
    private readonly addEmailPrefix = '/v1/hrms/paydatacollection/email/';
    private readonly hrisEmailPrefix = '/v1/hrms/payhub/hris/exports';

    constructor(
        private httpClient: HttpClient,
        private constService: KfSharedConstantsService,
        private authService: KfAuthService) {
            this.loggedInClientId = this.authService.getSessionInfo().User.ClientId;
            this.userId = this.authService.getSessionInfo().User.userId;
         }

   public search(
        searchString: string, appliedFilters: KfFilterMetadata[],
        sorting: any[], pageIndex: number, pageSize: number = 20,
    ): Observable<KfIpagedSearchResults & { data: EmailDetails }> {

        let sortBy: string;
        let sortColumn: string;
        if (sorting.length === 0) {
            sortBy = 'DESC';
            sortColumn = '';
        } else {
            sortBy = sorting[0].sortBy;
            sortColumn = sorting[0].sortColumn;
        }

        let filterBy = '';
        let filterValues = '';
        if (appliedFilters && appliedFilters.length > 0) {
            appliedFilters.forEach((filter: any) => {
                if (filter.values.length > 0) {
                    filterBy = filterBy + filter.id + '|';
                    filterValues = filterValues + filter.values.join(';') + '|';
                }
            });
        } else {
            filterBy = '';
            filterValues = '';
        }

        if(filterBy.length && (filterBy.length===filterBy.lastIndexOf("|")+1)){
            filterBy = filterBy.substr(0,filterBy.length-1);
        }
        if(filterValues.length && (filterValues.length===filterValues.lastIndexOf("|")+1)){
            filterValues = filterValues.substr(0,filterValues.length-1);
        }
        // const paramsData = {
        //     type: 'SEARCH_EMAIL_TEMPLATE',
        //     sortColumn,
        //     sortBy,
        //     searchString,
        //     searchColumn: 'EMAIL_NAME',
        //     filterBy,
        //     filterValues,
        //     pageIndex: pageIndex.toString(),
        //     pageSize: pageSize.toString(),
        // };

        const paramsData = {
            sortColumn,
            sortBy,
            searchString,
            searchColumn:"",
            filterBy,
            filterValues,
            pageIndex: pageIndex.toString(),
            pageSize: pageSize.toString(),
        };

        if (searchString == null || searchString.trim() === '') {
            delete paramsData.searchString;
        }
        const base = this.constService.getBaseApiUrl();
        const url = base + this.emailPrefix;
        const params = new HttpParams({ fromObject: paramsData });
        return this.authService.authHttpCall('get', url + '?' + params.toString());
    }

    public getCountryList():Observable<any>{
        const base = this.constService.getBaseApiUrl();
        const url = base + this.countryPrefix;
        const paramsData = {
            outputType : 'LITE',
            isMock: 'false'
        };
        const params = new HttpParams({ fromObject: paramsData });
        return this.authService.authHttpCall('get', url);
    }

    public getIndustryList():Observable<any>{
        const base = this.constService.getBaseApiUrl();
        const url = base + this.industryPrefix;
        return this.authService.authHttpCall('get', url);
    }

    public getDefaultTemplate():Observable<any>{
        const base = this.constService.getBaseApiUrl();
        const url = base + this.defaultTemplate;
        return this.authService.authHttpCall('get', url);
    }

    public saveEmailTemplate(emailMsg: any):Observable<any>{
        const base = this.constService.getBaseApiUrl();
        const url = base + this.addEmailPrefix;
        const paramsData = {
            loggedInUserClientId: this.loggedInClientId,
            userId: this.userId,
            locale: 'en',
            emailmessage : emailMsg
        };
        return this.authService.authHttpCall('POST', url, paramsData, null, true);
    }

    public getHrisEmailTemplate(val):Observable<any>{
        const base = this.constService.getBaseApiUrl();
        let url  = val === 'ALL' ? base + this.hrisEmailPrefix :base + this.hrisEmailPrefix +'?timeStamp=' + val;
        //const url = base + this.hrisEmailPrefix +'?timeStamp=' + val;
        return this.authService.authHttpCall('GET', url,null,null,true);
    }
    async getHrisEmailTemplateHRIS(val){
        const base = this.constService.getBaseApiUrl();
        let url  = val === 'ALL' ? base + this.hrisEmailPrefix :base + this.hrisEmailPrefix +'?timeStamp=' + val;
        //const url = base + this.hrisEmailPrefix +'?timeStamp=' + val;
       return await axios({
            method: 'get',
            url: url,
            headers: {
                'authToken': this.authService.AuthToken,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Content-Disposition': 'attachment',
            },
            responseType: 'blob',

        });
    }
}
