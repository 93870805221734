
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import {
    KfAuthService,
    KfSharedConstantsService,
} from '@kf-products-core/kfhub_lib';
import { Observable } from 'rxjs';

@Injectable()
export class CustomTranslationsService {
    private readonly servicePrefix = '/v1/hrms/thportal/admin/custom-translations';

    constructor(
        private authService: KfAuthService,
        private constService: KfSharedConstantsService
    ) { }

    public getTranslationsTemplate(
        clientId: number,
        lcId: string,
        userId: number,
        clientProfileIds: string[], 
    ) : Observable<any> {
        const base = this.constService.getBaseApiUrl();
        const url = base + this.servicePrefix;

        const data = {
            clientId,
            lcId,
            clientProfileIds,
            userId
        };

        return this.authService.authHttpCall('POST', url, data);
    }

    public importTranslations(
        clientId: number,
        lcId: string,
        userId: number,
        fileData: ArrayBuffer, 
    ) : Observable<any> {
        const base = this.constService.getBaseApiUrl();
        const url = base + this.servicePrefix + `/upload`;
        const bytes = new Uint8Array(fileData);

        const data = {
            clientId,
            lcId,
            userId,
            fileData: { type: 'Buffer', data: Array.from(bytes) },
        };

        return this.authService.authHttpCall('POST', url, data);
    }
}
